import { constantRoutes } from '@/router'
import { getUserMenu } from '@/api/menu'
import { setMenu, formatTree } from '../../utils/role'

export default {
  namespaced: true,
  state: () => ({
    routes: [],
    addRoutes: []
  }),
  mutations: {
    setRoutes: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    resetRoutes: (state) => {
      state.routes = []
    },
    resetAddRoutes: (state) => {
      state.addRoutes = []
    }
  },
  actions: {
    generateRoutes({ commit }, roles) {
      return new Promise((resolve) => {
        getUserMenu({ roles }).then((data) => {
          const menu = data
          const newMenu = formatTree(setMenu(menu))
          commit('setRoutes', newMenu)
          resolve(newMenu)
        })
      })
    },
    resetRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        commit('resetRoutes')
        resolve()
      })
    },
    resetAddRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        commit('resetAddRoutes')
        resolve()
      })
    }
  }
}
