import request from '@/utils/request'

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/user/login',
    method: 'POST',
    data
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/user/profile',
    method: 'get'
  })
}

/**
 * 获取用户列表
 */
export const getUserList = (params) => {
  return request({
    url: '/user/list',
    method: 'get',
    params
  })
}

/**
 * 新增用户
 * */
export const addUser = (data) => {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

/**
 * 删除用户
 */
export const deleteUser = (params) => {
  return request({
    url: '/user/delete',
    method: 'get',
    params
  })
}

/**
 * 编辑用户
 *
 */
export const editUser = (data) => {
  return request({
    url: '/user',
    method: 'put',
    data
  })
}

/**
 * 修改用户状态
 */
export const switchUserStatus = (params) => {
  return request({
    url: '/user/switch',
    method: 'get',
    params
  })
}
