<template>
  <div class="addKefuDialog">
    <el-dialog
      :title="uploadKefuData ? '编辑客服' : '添加客服'"
      v-model="dialogVisible"
      width="40%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="formRef"
        :model="form"
        :label-position="labelPosition"
        label-width="90px"
        status-icon
        :rules="rules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="客服姓名" prop="name">
              <el-input
                v-model="form.name"
                placeholder="填写客服姓名"
                autocomplete="new-name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信" prop="weixin">
              <el-input
                v-model="form.weixin"
                placeholder="填写客服微信"
                autocomplete="new-weixin"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="手机" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="填写手机"
                autocomplete="new-phone"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="QQ" prop="qq">
              <el-input
                v-model="form.qq"
                placeholder="填写QQ"
                autocomplete="new-qq"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="form.sex" style="width: 100%">
                <el-option
                  v-for="item in sexOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客服组" prop="group">
              <el-select v-model="form.group" style="width: 100%">
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="+item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="24">
            <el-form-item label="微信二维码">
              <div v-if="!form.qrcode && uploadKefuData" style="width: 100%">
                暂无微信二维码
              </div>
              <el-upload
                ref="uploadRef"
                :headers="headers"
                :action="action"
                name="qrcode"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :limit="1"
                :file-list="fileList"
                accept="png/jpg/jpeg"
                :before-upload="beforeUpload"
                :on-exceed="onExceed"
                :on-error="onError"
                :on-success="success"
              >
                <svg-icon icon="add" class="qrcode-add" />
              </el-upload>
              <img
                v-show="dialogImageUrl"
                width="50%"
                :src="dialogImageUrl"
                alt=""
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog" style="text-align: right">
        <el-button @click="handleClose">取 消</el-button>
        <el-button @click="handleAddKefu" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { addKefu } from '@/api/customer'
import { getToken } from '@/utils/auth'
import { ElMessage, ElNotification } from 'element-plus'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'

const fileds = {
  name: '客服姓名',
  weixin: '微信',
  sex: '性别',
  group: '客服组'
}

const action = ref('https://ctback.aishangjf.com:18083/v1/kefu/qrcode')
const headers = computed(() => ({ Authorization: `Bearer ${getToken()}` }))
const store = useStore()

// eslint-disable-next-line
const props = defineProps(['uploadKefuData', 'groupList'])
const formRef = ref(null)
const uploadRef = ref(null)
const dialogVisible = ref(false)
const labelPosition = ref('left')
const dialogImageUrl = ref('')
const sexOptions = ref([
  {
    label: '男',
    value: '男'
  },
  {
    label: '女',
    value: '女'
  }
])
const fileList = ref([])
const oldAvatar = ref(false)
const form = reactive({
  userId: store.getters.userInfo.id,
  name: '',
  weixin: '',
  phone: '',
  qq: '',
  qrcode: '',
  sex: '',
  group: '',
  isDelted: 0
})

// eslint-disable-next-line
const emits = defineEmits(['onError'])

// 校验规则
const validateRequire = (rule, value, callback) => {
  if (!value) {
    callback(new Error(fileds[rule.field] + '必须填写'))
  } else {
    callback()
  }
}

const rules = reactive({
  name: [{ required: true, validator: validateRequire }],
  weixin: [{ required: true, validator: validateRequire }],
  sex: [{ required: true, validator: validateRequire }],
  group: [{ required: true, validator: validateRequire }]
})

const openDialog = () => {
  init()
  dialogVisible.value = true
}

const handleClose = () => {
  dialogVisible.value = false

  dialogVisible.value = false
  dialogImageUrl.value = false
  formRef.value.resetFields()
  if (uploadRef.value) {
    // 清空上传图片
    uploadRef.value.clearFiles()
  }
}

const init = () => {
  if (props.uploadKefuData) {
    // 编辑
  }
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.rule
  dialogVisible.value = true
}

const handleRemove = () => {
  dialogImageUrl.value = ''
  form.qrcode = ''
}

const beforeUpload = (file) => {
  const uploadType = file.name.substring(file.name.lastIndexOf('.') + 1)
  const imgType = uploadType === 'jpg'
  const imgType1 = uploadType === 'jpeg'
  const imgType2 = uploadType === 'png'
  if (!imgType && !imgType1 && !imgType2) {
    this.$message({
      message: '二维码只能是jpg,jpeg,gif格式',
      type: 'warning'
    })
  }
  oldAvatar.value = false
  return imgType || imgType1 || imgType2
}

const onExceed = () => {
  ElMessage({
    message: '每次只能上传一张图片',
    type: 'warning'
  })
}

const success = (file) => {
  form.qrcode = file.data
}

const onError = (err) => {
  const errMsg = err.message && JSON.parse(err.message)
  ElMessage({
    message:
      (errMsg && errMsg.msg && `上传失败，失败原因：${errMsg.msg}`) ||
      '上传失败',
    type: 'error'
  })
  emits('onError', err)
}

const handleAddKefu = () => {
  submitForm()
}

const submitForm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      addOrUpdateKefu()
    } else {
      return false
    }
  })
}

const addOrUpdateKefu = async () => {
  if (props.uploadKefuData) {
    // 编辑
    console.log('编辑')
  } else {
    // 新增
    await addKefu(form).then(() => {
      ElNotification({
        title: '成功',
        message: '添加客服成功',
        type: 'success'
      })
    })
  }
  handleClose()
  // eslint-disable-next-line
  emits('updateList')
}
</script>

<style lang="scss" scoped>
.qrcode-add {
  cursor: pointer;
  font-size: 22px;
  vertical-align: middle;
}
</style>
