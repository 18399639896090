<template>
  <div class="menu-container">
    <el-card class="header">
      <div style="display: inline-block">
        <el-button
          class="filter-item"
          type="primary"
          @click="openDialog('addNewData')"
        >
          {{ $t('msg.button.add') }}
        </el-button>
      </div>
    </el-card>
    <el-card>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        row-key="id"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="id" label="ID" width="180" align="center" />
        <el-table-column
          prop="name"
          label="菜单名称"
          width="180"
          align="center"
        />
        <el-table-column prop="url" label="URL" align="center" />
        <el-table-column prop="pid" label="父菜单" width="180" align="center" />
        <el-table-column prop="sort" label="排序" width="180" align="center" />
        <el-table-column label="操作" width="320" align="center" fixed="right">
          <template #default="{ row }">
            <el-button type="primary" @click="openDialog('addData', row)">{{
              $t('msg.button.add')
            }}</el-button>
            <el-button type="success" @click="openDialog('edit', row)">{{
              $t('msg.button.edit')
            }}</el-button>
            <el-button
              type="danger"
              @click="onDeleteMenuClick(row)"
              style="margin: 0 10px"
            >
              {{ $t('msg.button.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="menuFormRef"
        :model="menuForm"
        :label-position="labelPosition"
        label-width="80px"
        status-icon
        :rules="rules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="menuForm.name" placeholder="请输入菜单" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="URL" prop="url">
              <el-input v-model="menuForm.url" placeholder="请输入菜单url" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="父菜单" prop="pid">
              <el-select
                v-model="menuForm.pid"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in pidData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="menuForm.sort" placeholder="请输入排序顺序" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="重定向" prop="redirect">
              <el-input
                v-model="menuForm.redirect"
                placeholder="请输入重定向地址"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="icon">
              <el-input v-model="menuForm.icon" placeholder="请输入菜单图标" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="是否隐藏" prop="hidden">
              <el-select
                v-model="menuForm.hidden"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in hiddenData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">
            {{ $t('msg.button.cancel') }}</el-button
          >
          <el-button type="primary" @click="handleAddMenu">
            {{ $t('msg.button.sure') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getMenuList, addMenu, updateMenu, deleteMenu } from '@/api/menu'
import { formatTree } from '@/utils/role'
import { nextTick } from 'vue'
// import { watchSwitchLang } from '@/utils/i18n'

const fileds = {
  name: '菜单名称',
  url: 'URL',
  pid: '父菜单',
  sort: '排序'
}

export default {
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value) {
        callback(new Error(fileds[rule.field] + '必须填写'))
      } else {
        callback()
      }
    }
    return {
      listQuery: {},
      tableData: [],
      dialogTitle: '新增',
      dialogVisible: false,
      labelPosition: 'left',
      menuForm: {
        name: '',
        url: '',
        pid: 0,
        sort: 1,
        redirect: '',
        icon: '',
        hidden: 0
      },
      pidData: [],
      hiddenData: [
        {
          label: '隐藏',
          value: 1
        },
        {
          label: '显示',
          value: 0
        }
      ],
      rules: {
        name: [{ required: true, validator: validateRequire }],
        url: [{ required: true, validator: validateRequire }],
        pid: [
          { required: true, message: '父菜单选项不能为空', trigger: ['blur'] }
        ],
        sort: [{ required: true, validator: validateRequire }]
      },
      menuArr: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async openDialog(type, row) {
      const res = await this.getList()

      this.dialogVisible = true
      await nextTick()
      this.pidData = res
      this.pidData.unshift({ id: 0, name: '顶级菜单', pid: 0 })
      this.menuForm.sort = 1
      if (type === 'edit' && row) {
        this.dialogTitle = '编辑菜单'
        let newMenuData = JSON.parse(JSON.stringify(row))
        if (newMenuData === 0) {
          newMenuData = '顶级菜单'
        }
        this.menuForm = newMenuData
        this.menuForm.id = newMenuData.id
      }
      if (type === 'addData' && row) {
        this.dialogTitle = '新增菜单'
        let newMenuData = JSON.parse(JSON.stringify(row))
        if (newMenuData === 0) {
          newMenuData = '顶级菜单'
        }
        this.menuForm.pid = newMenuData.id
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.menuFormRef.resetFields()
    },
    async getList() {
      const result = await getMenuList(this.listQuery)
      this.tableData = formatTree(result)
      return result
    },
    async getPidData() {
      const result = await getMenuList()
      this.pidData = result
      this.pidData.unshift({ id: 0, name: '顶级菜单', pid: 0 })
    },
    handleAddMenu() {
      this.submitForm('menuFormRef')
    },
    submitForm(formName) {
      const newRule = [...this.rules.pid, { required: true, trigger: 'change' }]
      this.rules = Object.assign({}, this.rules, { pid: newRule })
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addOrUddateMenu()
        } else {
          return false
        }
      })
    },
    async addOrUddateMenu() {
      const { sort, pid, name, url, id, redirect, icon, hidden } = this.menuForm
      let newParentId
      if (pid === '顶级菜单') {
        newParentId = 0
      } else {
        newParentId = pid
      }
      const newQuery = {
        pid: newParentId,
        name,
        url,
        sort,
        redirect,
        icon,
        hidden
      }
      if (this.dialogTitle === '编辑菜单') {
        newQuery.id = id
        await updateMenu(newQuery)
        this.$notify({
          title: '成功',
          message: '更新菜单成功',
          type: 'success',
          duration: 2000
        })
      } else {
        await addMenu(newQuery)
        this.$notify({
          title: '成功',
          message: '添加菜单成功',
          type: 'success',
          duration: 2000
        })
      }
      setTimeout(() => {
        this.$router.go()
      }, 1500)
      this.handleClose()
    },
    getMenId(data) {
      data.children.map((item) => {
        if (item.children) {
          this.menuArr.push(item.id)
          this.getMenId(item)
        }
        this.menuArr.push(item.id)
      })
    },
    async onDeleteMenuClick(row) {
      this.menuArr = []
      if (row.children) {
        this.getMenId(row)
      }
      const newRow = {
        id: row.id,
        name: row.name,
        pid: row.pid,
        sort: row.sort
      }
      this.$confirm('此操作将永久删菜单，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMenu(newRow).then(() => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
}
</style>
