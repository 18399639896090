export default {
  login: {
    title: 'User Login',
    loginBtn: 'Login',
    usernameRule: 'Username is required',
    passwordRule: 'Password cannot be less than 6 digits'
  },
  logoTitle: {
    title: 'HoursWeb'
  },
  navBar: {
    themeChange: 'Theme Modification',
    headerSearch: 'Page Search',
    screenfull: 'Full Screen Replacement',
    lang: 'Globalization',
    guide: 'Function Guide',
    home: 'Home',
    course: 'Course homepage',
    logout: 'Log out'
  },
  guide: {
    close: 'close',
    next: 'next',
    prev: 'previous',
    guideTitle: 'guidance',
    guideDesc: 'Turn on the boot function',
    hamburgerTitle: 'Hamburger button',
    hamburgerDesc: 'Open and close the left menu',
    breadcrumbTitle: 'Bread crumbs',
    breadcrumbDesc: 'Indicates the current page position',
    searchTitle: 'search',
    searchDesc: 'Page link search',
    fullTitle: 'full screen',
    fullDesc: 'Page display switching',
    themeTitle: 'theme',
    themeDesc: 'Change project theme',
    langTitle: 'globalization',
    langDesc: 'Language switch',
    tagTitle: 'Label',
    tagDesc: 'Opened page tab',
    sidebarTitle: 'menu',
    sidebarDesc: 'Project function menu'
  },
  toast: {
    switchLangSuccess: 'Switch Language Success'
  },
  route: {
    home: 'Home',
    home_P: 'Home',
    authority: 'authority',
    user: 'user',
    role: 'role',
    menu: 'menu',
    loadingPage: 'loadingPage',
    loadingPage_P: 'loadingPage',
    material: 'material',
    newMaterialStore: 'materialStore',
    customer: 'customer',
    add: 'addCustomerService',
    group: 'customerServiceGroup',
    formManager_P: 'formManager',
    formManager: 'formManager'
  },
  tagsView: {
    refresh: 'Refresh',
    closeRight: 'Close Rights',
    closeOther: 'Close Others'
  },
  theme: {
    themeColorChange: 'Theme Color Change',
    themeChange: 'Theme Change'
  },
  universal: {
    confirm: 'confirm',
    cancel: 'cancel'
  },
  button: {
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    cancel: 'cancel',
    sure: 'sure',
    search: 'search',
    login: 'login',
    addMaterial: 'addMaterial',
    addType: 'addType',
    addCustomerGroup: 'addCustomerGroup',
    addProjectType: 'addProjectType',
    put: 'put',
    isPut: 'isPuted'
  },
  filter: {
    name: 'name',
    nickname: 'nickname',
    email: 'email',
    role: 'role',
    type: 'type',
    classify: 'classify',
    url: 'url',
    customerGroup: 'customerGroup',
    groupName: 'groupName'
  }
}
