import request from '@/utils/request'

export function getRoleList(params) {
  return request({
    url: '/role/getRoleList',
    method: 'get',
    params
  })
}

export function addRole(data) {
  return request({
    url: '/role',
    method: 'post',
    data
  })
}

export function updateRole(data) {
  return request({
    url: '/role/updateRole',
    method: 'post',
    data
  })
}

export function deleteRole(params) {
  return request({
    url: '/role/deleteRole',
    method: 'get',
    params
  })
}

export function getUserRoleList(params) {
  return request({
    url: '/role/getMenuRole',
    method: 'get',
    params
  })
}

export function getCurrentUserRoleList(id) {
  return request({
    url: '/role/getCurrentUserRoleList',
    method: 'get',
    params: {
      id
    }
  })
}
