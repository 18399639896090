<template>
  <div class="user-container">
    <el-card class="header">
      <div class="filter-container">
        <el-input
          v-model="listQuery.username"
          :placeholder="$t('msg.filter.name')"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-input
          v-model="listQuery.nickname"
          :placeholder="$t('msg.filter.nickname')"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-input
          v-model="listQuery.email"
          :placeholder="$t('msg.filter.email')"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-select
          v-model="listQuery.role"
          :placeholder="$t('msg.filter.role')"
          style="width: 200px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
        <el-select
          v-model="listQuery.is_deleted"
          placeholder="用户状态"
          style="width: 100px; margin-right: 10px"
        >
          <el-option
            v-for="item in userStatusOptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button class="filter-item" @click="handlerFilter" type="primary">
          <svg-icon icon="search" class-name="search-icon"></svg-icon>
        </el-button>
        <div style="display: inline-block; margin-left: auto">
          <user-role-add @updateList="getList" :role-list="roleList">
            <el-button class="filter-item" type="primary">
              {{ $t('msg.button.add') }}
            </el-button>
          </user-role-add>
        </div>
      </div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <el-table-column label="用户名" align="center">
          <template #default="{ row: { usernameWrapper } }">
            <span v-html="usernameWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" align="center">
          <template #default="{ row: { nicknameWrapper } }">
            <span v-html="nicknameWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="权限" align="center">
          <template #default="{ row: { roleWrapper } }">
            <span v-html="roleWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="客户组" align="center">
          <template #default="{ row: { groupWrapper } }">
            <span v-html="groupWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="用户头像" align="center">
          <template #default="{ row }">
            <img
              style="width: 50px; height: 50px; vertical-align: middle"
              :src="row.avatar"
              fit
            />
          </template>
        </el-table-column>
        <el-table-column label="用户邮箱" align="center">
          <template #default="{ row: { emailWrapper } }">
            <span v-html="emailWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="用户状态" align="center">
          <template #default="{ row }">
            <el-switch
              v-model="row.isDeleted"
              :active-value="0"
              :inactive-value="1"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              @change="userStatusChange(row.id, row.isDeleted)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center" fixed="right">
          <template #default="{ row }">
            <div style="display: inline-block; margin-right: 10px">
              <user-role-add
                :user-data="row"
                :role-list="roleList"
                @updateList="getList"
              >
                <el-button type="success">{{
                  $t('msg.button.edit')
                }}</el-button>
              </user-role-add>
            </div>
            <el-button type="primary" @click="handleLogin(row)">{{
              $t('msg.button.login')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <Pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="refresh"
    />
  </div>
</template>

<script>
import UserRoleAdd from './components/UserRoleAdd'
import Pagination from '@/components/Pagination'
import { getCurrentUserRoleList } from '@/api/role'
import { getUserList, deleteUser, switchUserStatus } from '@/api/user'
export default {
  components: {
    UserRoleAdd,
    Pagination
  },
  data() {
    return {
      listQuery: {},
      tableKey: 0,
      list: [],
      listLoading: false,
      defaultSort: {},
      total: 0,
      roleList: [],
      userId: 0,
      userStatusOptions: [
        {
          id: 1,
          label: '开启',
          value: 0
        },
        {
          id: 2,
          label: '关闭',
          value: 1
        }
      ]
    }
  },
  created() {
    this.parseQuery()
    this.userId = this.$store.getters.userInfo.id
  },
  mounted() {
    this.getCurrentUserRoleList(this.userId)
    this.getList()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      const newQuery = Object.assign({}, to.query)
      const oldQuery = Object.assign({}, from.query)
      if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
        this.getList()
      }
    }
    next()
  },
  methods: {
    parseQuery() {
      const query = Object.assign({}, this.$route.query)
      let sort = '+id'
      const listQuery = {
        page: 1,
        pageSize: 20,
        sort,
        is_deleted: 0
      }
      if (query) {
        query.page && (query.page = +query.page)
        query.pageSize && (query.pageSize = +query.pageSize)
        query.sort && (sort = query.sort)
        query.is_deleted && (query.is_deleted = +query.is_deleted)
      }
      const sortSymbol = sort[0]
      const sortColumn = sort.slice(1, sort.length)
      this.defaultSort = {
        prop: sortColumn,
        order: sortSymbol === '+' ? 'ascending' : 'descending'
      }
      this.listQuery = { ...listQuery, ...query }
    },
    sortChange(data) {
      const { prop, order } = data
      this.sortBy(prop, order)
    },
    sortBy(prop, order) {
      if (order === 'ascending') {
        this.listQuery.sort = `+${prop}`
      } else {
        this.listQuery.sort = `-${prop}`
      }
      this.handlerFilter()
    },
    wrapperKeyword(k, v) {
      function highlight(value) {
        return `<span style="color: #1890ff">${value}</span>`
      }
      if (!this.listQuery[k]) {
        return v
      } else {
        return v.replace(new RegExp(this.listQuery[k], 'ig'), (v) =>
          highlight(v)
        )
      }
    },
    handlerFilter() {
      this.listQuery.page = 1
      this.refresh()
    },
    refresh() {
      this.$router.push({
        path: '/authority/user',
        query: this.listQuery
      })
    },
    userStatusChange(id, isDeleted) {
      switchUserStatus({ id, isDeleted }).then(() => {
        this.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        })
        this.getList()
      })
    },
    handleDelete(data) {
      this.$confirm('此操作将永久删除用户，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUser(data).then((data) => {
          this.$notify({
            title: '成功',
            message: data.msg || '删除成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        })
      })
    },
    async getCurrentUserRoleList(userId) {
      const res = await getCurrentUserRoleList(userId)
      this.roleList = res
    },
    handleLogin(row) {
      const { username, password } = row
      this.$confirm('是否登录该账户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout()
        setTimeout(() => {
          this.$store
            .dispatch('user/login', {
              username,
              password,
              type: 'quick'
            })
            .then(() => {
              this.$router.push(
                `/login?redirect=${this.$router.currentRoute.value.fullPath}`
              )
            })
        })
      })
    },
    // 退出
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(
        `/login?redirect=${this.$router.currentRoute.value.fullPath}`
      )
    },
    getList() {
      this.listLoading = true
      getUserList(this.listQuery)
        .then((data) => {
          this.listLoading = false
          const { list, total } = data
          this.list = list
          this.total = total
          this.list.forEach((user) => {
            user.usernameWrapper = this.wrapperKeyword(
              'username',
              user.username
            )
            user.nicknameWrapper = this.wrapperKeyword(
              'nickname',
              user.nickname
            )
            user.emailWrapper = this.wrapperKeyword('email', user.email)
            user.roleWrapper = this.wrapperKeyword('role', user.role)
            user.groupWrapper = this.wrapperKeyword('group', user.group)
          })
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
    .filter-container {
      display: flex;
      align-items: center;
      .filter-item {
        .search-icon {
          fill: #fff;
        }
      }
    }
  }
}
</style>
