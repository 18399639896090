<template>
  <div class="role">
    <el-dialog
      :title="updateRoleData ? '编辑角色' : '新增角色'"
      v-model="dialogVisible"
      width="60%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="role">
        <el-form
          ref="roleForm"
          :model="roleForm"
          :label-position="labelPosition"
          label-width="80px"
          status-icon
          :validate-on-rule-change="false"
          :rules="rules"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称" prop="name">
                <el-input v-model="roleForm.name" placeholder="请输入名称" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="角色级别" prop="level">
                <el-input
                  v-model="roleForm.level"
                  placeholder="请输入角色级别"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="24">
            <el-form-item label="菜单" prop="menu">
              <el-tree
                ref="tree"
                :data="menuData"
                show-checkbox
                node-key="id"
                default-expand-all
                :props="defaultProps"
                :default-checked-keys="defaultChecked"
                :check-strictly="true"
                @check="handleCheck"
              />
            </el-form-item>
          </el-col>
        </el-form>
        <div class="dialog" style="text-align: right">
          <el-button @click="handleClose">
            {{ $t('msg.button.cancel') }}</el-button
          >
          <el-button @click="handleAddRole" type="primary">
            {{ $t('msg.button.sure') }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script>
import { getMenuList } from '@/api/menu'
import { getUserRoleList, addRole, updateRole } from '../../../api/role'
import { formatTree } from '../../../utils/role'

const fields = {
  name: '角色名称',
  menu: '菜单'
}
export default {
  name: 'RoleFormDialog',
  /* eslint-disable vue/require-prop-types */
  props: ['updateRoleData'],
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value) {
        callback(new Error(fields[rule.field] + '必须填写'))
      } else {
        callback()
      }
    }
    return {
      labelPosition: 'left',
      roleId: '', // 记录角色id,
      roleForm: {
        menu: [],
        name: '',
        level: ''
      },
      dialogVisible: false,
      defaultChecked: [],
      menuData: [],
      rules: {
        name: [{ required: true, validator: validateRequire }],
        level: [
          {
            required: true,
            message: '角色级别不能为空',
            trigger: ['change', 'focus']
          }
        ],
        menu: [
          {
            required: true,
            message: '菜单不能为空',
            trigger: ['change', 'focus']
          }
        ]
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    openDialog() {
      this.init()
      this.getList()
      this.dialogVisible = true
    },
    handleClose() {
      this.$refs.roleForm.resetFields()
      this.dialogVisible = false
    },
    init() {
      if (this.updateRoleData) {
        const newData = []
        getUserRoleList(this.updateRoleData).then((data) => {
          data.map((item) => {
            newData.push(item.menu_id)
          })
          this.roleForm.name = this.updateRoleData.name
          this.roleForm.level = this.updateRoleData.level
          this.roleForm.menu = newData
          this.defaultChecked = newData
          this.roleId = this.updateRoleData.id
        })
      }
    },
    handleAddRole() {
      this.submitForm('roleForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addOrUpdate()
        } else {
          return false
        }
      })
    },
    async addOrUpdate() {
      const roleName = this.roleForm.name
      const menuIds = this.newMenuData(this.$refs.tree.getCheckedNodes())
      const RoleId = this.roleId
      const level = +this.roleForm.level
      const roleData = {
        name: roleName,
        level,
        menu: menuIds
      }
      if (!this.updateRoleData) {
        await addRole(roleData).then((res) => {
          this.$notify({
            title: '成功',
            message: '添加角色成功',
            type: 'success',
            duration: 2000
          })
        })
      } else {
        roleData.id = RoleId
        await updateRole(roleData).then((res) => {
          this.$notify({
            title: '成功',
            message: res.msg || '编辑角色成功',
            type: 'success',
            duration: 2000
          })
        })
      }
      await this.$router.go()
      this.dialogVisible = false
      this.$refs.roleForm.resetFields()
      this.$emit('updateList')
    },
    newMenuData(checkedNodes) {
      const menuIds = []
      checkedNodes.forEach((nodes) => {
        menuIds.push(nodes.id, nodes.pid)
      })
      return Array.from(new Set(menuIds))
    },
    handleCheck() {
      this.roleForm.menu = this.$refs.tree.getCheckedNodes()
      this.$refs.roleForm.validateField('menu')
    },
    getList() {
      getMenuList().then((data) => {
        this.menuData = formatTree(data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-tree__empty-text) {
  width: 100px;
  transform: translate(-20%, -90%);
}
</style>
