<template>
  <div class="material-container">
    <el-card class="header">
      <div class="filter-container">
        <span>{{ $t('msg.filter.type') }}：</span>
        <el-select
          v-model="listQuery.type"
          :placeholder="$t('msg.filter.type')"
          style="width: 150px; margin-right: 10px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <span>{{ $t('msg.filter.classify') }}：</span>
        <el-select
          v-model="listQuery.classify"
          :placeholder="$t('msg.filter.classify')"
          style="width: 150px; margin-right: 10px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <span>{{ $t('msg.filter.url') }}：</span>
        <el-input
          v-model="listQuery.url"
          :placeholder="$t('msg.filter.url')"
          style="width: 280px; margin-right: 10px;"
          class="filter-item"
        />
        <el-button class="filter-item" @click="handlerFilter" type="primary">
          <svg-icon icon="search" class-name="search-icon"></svg-icon>
        </el-button>
        <el-button
          class="filter-item"
          type="primary"
          style="margin-left: auto"
          >{{ $t('msg.button.addMaterial') }}</el-button
        >
        <el-button class="filter-item" type="success">{{
          $t('msg.button.addType')
        }}</el-button>
      </div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <el-table-column label="链接" align="center">
          <template #default="{ row: { linkWrapper } }">
            <a v-html="linkWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="落地页类型" align="center">
          <template #default="{ row: { typeWrapper } }">
            <span v-html="typeWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="分类" align="center">
          <template #default="{ row: { classifyWrapper } }">
            <span v-html="classifyWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title" align="center" />
        <el-table-column label="推荐理由" prop="reason" align="center" />
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableKey: 0,
      listQuery: {},
      typeList: [],
      classifyList: []
    }
  },
  methods: {
    handlerFilter() {}
  }
}
</script>

<style lang="scss" scoped>
.material-container {
  .header {
    margin-bottom: 22px;
    .filter-container {
      display: flex;
      align-items: center;
      .search-icon {
        fill: #fff;
      }
    }
  }
}
</style>
