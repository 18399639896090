import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
import { getToken, isCheckTimeout } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // 登出操作
        await handleTokenTimeout()
        return Promise.reject(new Error('token 失效'))
      }
      config.headers.Authorization = `Bearer ${getToken()}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    const { code, data, msg } = res
    const strCode = code.toString()
    if (/^[23]\d{2}$/.test(strCode)) {
      return data
    } else {
      // 业务错误
      handleBusinessError(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    handleErrorResponse(error)
    return Promise.reject(error)
  }
)

// 处理 token 过期
async function handleTokenTimeout() {
  ElMessageBox.alert('Token 已失效，请重新登录。', {
    'show-close': false,
    confirmButtonText: '重新登录',
    type: 'warning'
  }).then(() => {
    store.dispatch('user/logout').then(() => {
      location.reload()
    })
  })
}

// 处理业务错误
function handleBusinessError(message) {
  ElMessage({
    type: 'error',
    message: message,
    duration: 3 * 1000
  })
}

// 处理响应错误
function handleErrorResponse(error) {
  let message = error.message || '请求失败'
  if (error.response) {
    const { data } = error.response
    message = data.msg
    if (error.response.status === 401) {
      // 处理 token 过期
      handleTokenExpired()
    } else {
      handleBusinessError(message)
    }
  }
}

// 处理 token 过期
function handleTokenExpired() {
  ElMessageBox.alert('Token 已失效，请重新登录。', {
    'show-close': false,
    confirmButtonText: '重新登录',
    type: 'warning'
  }).then(() => {
    store.dispatch('user/resetToken').then(() => {
      location.reload()
    })
  })
}

export default service
