import request from '@/utils/request'

// 获取客服组列表
export const getGroupList = (params) => {
  return request({
    url: '/group',
    method: 'get',
    params
  })
}

// 获取客服组下拉选项
export const getSelectGroup = (params) => {
  return request({
    url: '/group',
    method: 'get',
    params
  })
}

// 添加客服组
export const addCustomerGroup = (data) => {
  return request({
    url: '/group',
    method: 'post',
    data
  })
}

// 获取客服分组下拉列表
export const getGroup = (params) => {
  return request({
    url: '/kefu/list',
    method: 'get',
    params
  })
}

// 添加客服
export const addKefu = (data) => {
  return request({
    url: '/kefu',
    method: 'post',
    data
  })
}

// 获取客服列表
export const getKefuList = (params) => {
  return request({
    url: '/kefu',
    method: 'get',
    params
  })
}
