<template>
  <div class="addLoadingPage">
    <el-dialog
      :title="updateLoadingPageData ? '编辑落地页' : '新增落地页'"
      v-model="dialogVisible"
      width="40%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="formRef"
        :model="form"
        :label-position="labelPosition"
        label-width="100px"
        status-icon
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <el-row :gutter="40">
          <el-col :span="24">
            <el-form-item
              label="上报平台"
              prop="platform"
              :label-width="labelWidth"
            >
              <el-radio-group
                v-model="form.platform"
                @change="handlePlatformChange"
              >
                <!-- <el-radio label="1">百度信息流</el-radio>
                <el-radio label="2">百度搜索</el-radio> -->
                <el-radio label="3">小红书</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="落地页类型"
              prop="loadingPageType"
              :label-width="labelWidth"
            >
              <el-radio-group v-model="form.loadingPageType">
                <el-radio label="1">自建站</el-radio>
                <!-- <el-radio label="2">外链</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-show="form.loadingPageType === '2' && showUrlInput"
          >
            <el-form-item
              label="落地页url"
              prop="url"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.url"
                placeholder="请填写落地页链接"
                autocomplete="new-url"
                :style="inputWidth"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-show="form.platform === '1' || form.platform === '2'"
          >
            <el-form-item
              label="OCPC Token"
              prop="bdOcpcToken"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.bdOcpcToken"
                placeholder="请填写百度OCPC Token"
                autocomplete="new-bdOcpcToken"
                :style="inputWidth"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '2'">
            <el-form-item
              label="百度营销账号"
              prop="bdUsername"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.bdUsername"
                placeholder="请填写百度营销账号"
                autocomplete="new-bdUsername"
                :style="inputWidth"
                @input="(e) => handleClearBaiduTJ(e)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '2'">
            <el-form-item
              label="百度营销密码"
              prop="bdPassword"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.bdPassword"
                :type="passType"
                placeholder="请填写百度营销密码"
                autocomplete="new-bdPassword"
                :style="inputWidth"
                class="no-autofill-pwd"
                @input="(e) => handleClearBaiduTJ(e)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '2'">
            <el-form-item
              label="百度统计Token"
              prop="bdTjToken"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.bdTjToken"
                placeholder="请填写百度统计token"
                autocomplete="new-bdTjToken"
                :style="inputWidth"
                @input="(e) => handleClearBaiduTJ(e)"
              >
                <template #append>
                  <el-button
                    style="background: #409eff; color: #fff"
                    @click="getBdTJSiteId"
                  >
                    获取站点id
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '2' && showSiteIdInput">
            <el-form-item
              label="站点id"
              prop="siteId"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.siteId"
                disabled
                placeholder="站点id"
                autocomplete="new-siteId"
                :style="inputWidth"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '3'">
            <el-form-item
              label="账户ID"
              prop="xhsAccountId"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.xhsAccountId"
                placeholder="请填写小红书账户ID"
                autocomplete="new-xhsAccountId"
                :style="inputWidth"
                @input="(e) => handleClearXhsAccessToken(e)"
              >
                <!-- <template #append>
                  <el-button
                    style="background: #409eff; color: #fff"
                    @click="getXhsAccessToken"
                    >获取Token</el-button
                  >
                </template> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.platform === '3'">
            <el-form-item
              label="数据推送token"
              prop="xhsDataPushToken"
              :label-width="labelWidth"
            >
              <el-input
                v-model="form.xhsDataPushToken"
                placeholder="请填写聚光平台创建落地页时提供的数据推送Token"
                autocomplete="new-xhsDataPushToken"
                :style="inputWidth"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="转化类型"
              prop="transformType"
              :label-width="labelWidth"
            >
              <el-select
                v-model="form.transformType"
                placeholder="请选择转化类型"
                :style="inputWidth"
              >
                <el-option
                  v-for="item in transformTypeOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item
              label="提交方式"
              prop="subType"
              :label-width="labelWidth"
            >
              <el-radio-group v-model="form.subType" :style="inputWidth">
                <el-radio label="0">自动提交</el-radio>
                <el-radio label="1">手动提交</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item
              label="转化时长过滤"
              prop="selectRule"
              :label-width="labelWidth"
            >
              <span>小于&nbsp;</span
              ><el-input
                v-model="form.selectRule"
                type="number"
                :min="0"
                style="width: 100px"
              /><span>&nbsp;秒不提交</span>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer" style="text-align: right">
          <el-button @click="handleClose">取 消</el-button>
          <el-button @click="handleLoadingPageAdd" type="primary"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { getBdSiteId } from '@/api/baiduApi'
import { createLoadingPage, uploadLoadingPage } from '@/api/loadingPage'
import { reactive, ref, watchEffect, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
// eslint-disable-next-line
const props = defineProps(['updateLoadingPageData', 'listQuery'])

const fileds = {
  platform: '上报平台',
  loadingPageType: '落地页类型',
  url: '落地页url',
  bdUsername: '百度营销账号',
  bdPassword: '百度营销密码',
  bdTjToken: '百度统计 Token',
  siteId: '站点ID',
  bdOcpcToken: '百度OCPC Token',
  xhsAccountId: '账户ID',
  xhsDataPushToken: '数据推送Token',
  transformType: '转化类型',
  subType: '提交方式'
}

const formRef = ref(null)
const dialogVisible = ref(false)
const labelPosition = ref('right')
const labelWidth = ref('200')
const inputWidth = ref({ width: '487px' })
const passType = ref('text')
const showSiteIdInput = ref(false)
const showUrlInput = ref(false)
const form = reactive({
  platform: '3',
  loadingPageType: '1',
  url: '',
  bdOcpcToken: '',
  bdUsername: '',
  bdPassword: '',
  bdTjToken: '',
  siteId: '',
  xhsAccountId: '',
  xhsDataPushToken: '',
  transformType: '',
  subType: '1',
  selectRule: 0
})

// 校验规则
const validateRequire = (rule, value, callback) => {
  if (!value) {
    callback(new Error(fileds[rule.field] + '必须填写'))
  } else {
    callback()
  }
}

const rules = reactive({
  platform: [{ required: true, validator: validateRequire }],
  loadingPageType: [{ required: true, validator: validateRequire }],
  url: [{ required: true, validator: validateRequire }],
  bdOcpcToken: [{ required: true, validator: validateRequire }],
  bdUsername: [{ required: true, validator: validateRequire }],
  bdPassword: [{ required: true, validator: validateRequire }],
  bdTjToken: [{ required: true, validator: validateRequire }],
  siteId: [{ required: true, validator: validateRequire }],
  xhsAccountId: [{ required: true, validator: validateRequire }],
  xhsDataPushToken: [{ required: true, validator: validateRequire }],
  transformType: [{ required: true, validator: validateRequire }],
  subType: [{ required: true, validator: validateRequire }]
})

const openDialog = () => {
  if (props.updateLoadingPageData) {
    // 使用 Object.assign 更新 form 对象
    Object.assign(form, props.updateLoadingPageData)
  }
  dialogVisible.value = true
}
const handleClose = () => {
  dialogVisible.value = false
  formRef.value.resetFields()
}

const transformTypeOptions = ref([])

const platFormOption = {
  1: [
    {
      name: '微信复制按钮点击',
      value: '35'
    },
    {
      name: '表单提交成功',
      value: '3'
    },
    {
      name: '微信加粉成功',
      value: '79'
    }
  ],
  2: [
    {
      name: '微信复制按钮点击',
      value: '35'
    },
    {
      name: '表单提交成功',
      value: '3'
    },
    {
      name: '微信加粉成功',
      value: '79'
    }
  ],
  3: [
    {
      name: '表单提交',
      value: '101'
    },
    {
      name: '有效表单',
      value: '102'
    }
    // {
    //   name: '微信复制',
    //   value: '121'
    // },
    // {
    //   name: '微信加好友',
    //   value: '122'
    // },
    // {
    //   name: '负向样本',
    //   value: '200'
    // }
  ]
}

// 监听 form.platform 的变化
watchEffect(() => {
  // 检查所选平台是否在 platFormOption 中
  if (form.platform in platFormOption) {
    transformTypeOptions.value = platFormOption[form.platform]
  } else {
    // 处理所选平台不在 platFormOption 中的情况
    console.error(`未定义平台 ${form.platform} 的选项。`)
  }
})
// eslint-disable-next-line
const emits = defineEmits()

onMounted(() => {
  setTimeout(() => {
    passType.value = 'password1'
  }, 400)
})

// 上报平台改变时
const handlePlatformChange = (val) => {
  formRef.value.clearValidate()
  initLoadingPageForm(val)
}

const initLoadingPageForm = (val) => {
  form.platform = val
  form.url = ''
  form.bdOcpcToken = ''
  form.xhsAccountId = ''
  form.transformType = ''
  form.subType = '1'
  form.selectRule = 0
}

const handleLoadingPageAdd = () => {
  submitForm()
}

const submitForm = () => {
  if (form.platform === '1') {
    formRef.value.validateField(
      ['platform', 'url', 'bdOcpcToken', 'transformType', 'subType'],
      (errMsg) => {
        if (errMsg) {
          // 创建百度信息流落地页
        }
      }
    )
  }

  if (form.platform === '2') {
    formRef.value.validateField(
      [
        'platform',
        'url',
        'bdOcpcToken',
        'bdUsername',
        'bdPassword',
        'bdTjToken',
        'transformType',
        'subType'
      ],
      (errMsg) => {}
    )
  }

  // 小红书聚光表单数据推送
  if (form.platform === '3') {
    formRef.value.validateField(
      [
        'platform',
        'loadingPageType',
        'xhsAccountId',
        'xhsDataPushToken',
        'transformType'
      ],
      async (errMsg) => {
        if (errMsg) {
          // 创建小红书落地页
          if (props.updateLoadingPageData) {
            await uploadLoadingPage(form)
            ElMessage.success('修改成功')
          } else {
            await createLoadingPage(form)
            ElMessage.success('添加成功')
          }
          handleClose()
          // eslint-disable-next-line
          emits('updateList')
        }
      }
    )
  }
}

// const getXhsAccessToken = () => {
//   formRef.value.validateField(['xhsAccountId'], (errMsg) => {
//     if (errMsg) {
//       console.log(form)
//     }
//   })
// }

const getBdTJSiteId = () => {
  formRef.value.validateField(
    ['bdUsername', 'bdPassword', 'bdTjToken'],
    (errMsg) => {
      if (errMsg) {
        // 获取站点id
        const formData = {
          bdUsername: form.bdUsername,
          bdPassword: form.bdPassword,
          bdTjToken: form.bdTjToken
        }
        getBdSiteId(formData).then((res) => {
          if (res.code === 200) {
            form.siteId = res.siteId
            showSiteIdInput.value = true
            ElMessage.success('获取站点id成功')
          }
        })
      }
    }
  )
}

const handleClearBaiduTJ = (e) => {
  form.siteId = ''
  showSiteIdInput.value = false
}

const handleClearXhsAccessToken = (e) => {}
</script>

<style scoped>
.no-autofill-pwd {
  -webkit-text-security: disc !important;
}
</style>
