export default {
  namespaced: true,
  state: () => ({
    id: '', // 落地页id
    xhsAccountId: '' // 小红书账户id
  }),
  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_XHS_ACCOUNT_Id: (state, xhsAccountId) => {
      state.xhsAccountId = xhsAccountId
    }
  },
  actions: {
    setId({ commit }, id) {
      commit('SET_ID', id)
    },
    setXhsAccountId({ commit }, xhsAccountId) {
      commit('SET_XHS_ACCOUNT_Id', xhsAccountId)
    }
  }
}
