import router from '@/router'
import store from '@/store'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
import en from '@/i18n/lang/en'
import zh from '@/i18n/lang/zh'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const title =
    store.getters.language === 'zh'
      ? zh.route[to.meta.title]
      : en.route[to.meta.title]
  document.title = getPageTitle(title)

  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.hasUserInfo) {
        next()
      } else {
        try {
          const { roles } = await store.dispatch('user/getUserInfo')

          const accessRoutes = await store.dispatch(
            'permission/generateRoutes',
            roles
          )

          accessRoutes.forEach((route) => {
            router.addRoute(route)
          })

          // 表示要做一次重定向
          if (to.name === '404') {
            next({ path: to.path, query: to.query })
          } else {
            // 请求带有重定向时，登录自动重定向到该地址
            if (from.query.redirect) {
              const redirect = decodeURIComponent(from.query.redirect)
              next({ path: redirect })
            } else {
              next({ ...to, replace: true })
            }
          }
        } catch (error) {
          await store.dispatch('user/resetToken')
          ElMessage.error(error || 'Has Error')
          next({
            path: '/login',
            query: { redirect: to.path, ...to.query }
          })
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.path, ...to.query }
      })
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
