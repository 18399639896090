import { login, getUserInfo } from '@/api/user'
import { removeAllItem } from '@/utils/storage'
import { setToken, getToken, setTimeStamp } from '@/utils/auth'
export default {
  namespaced: true,
  state: () => ({
    token: getToken() || '',
    userInfo: {}
  }),
  mutations: {
    setToken: (state, token) => {
      state.token = token
      setToken(token)
    },
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((data) => {
            commit('setToken', data.token)
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((userInfo) => {
          const { roles } = userInfo
          if (!roles || roles.length <= 0) {
            reject(new Error('获取用户信息失败：角色必须是一个非空数组'))
          }
          commit('setUserInfo', userInfo)
          resolve(userInfo)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('setToken', '')
          commit('setUserInfo', {})
          this.dispatch('permission/resetRoutes')
          this.dispatch('permission/resetAddRoutes')
          removeAllItem()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    resetToken({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setToken', '')
        commit('setUserInfo', {})
        this.dispatch('permission/resetRoutes')
        this.dispatch('permission/resetAddRoutes')
        // removeToken()
        removeAllItem()
        resolve()
      })
    }
  }
}
