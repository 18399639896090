<template>
  <div class="loadingPage-container">
    <el-card class="header">
      <div class="filter-container">
        <!-- <el-input
          v-model="listQuery.url"
          placeholder="请输入要搜索的域名"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-select
          v-model="listQuery.type"
          placeholder="请选择项目类型"
          style="width: 180px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in projectFormData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-model="listQuery.status"
          placeholder="落地页状态"
          style="width: 100px; margin-right: 10px"
        >
          <el-option
            v-for="item in pageStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button class="filter-item" @click="handlerFilter" type="primary">
          <svg-icon icon="search" class-name="search-icon"></svg-icon>
        </el-button> -->
        <div style="display: inline-block; margin-left: auto">
          <loading-page-add @updateList="getList">
            <el-button
              class="filter-item"
              type="primary"
              style="margin-left: auto"
            >
              {{ $t('msg.button.addLoadingPage') }}
            </el-button>
          </loading-page-add>
        </div>
      </div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <el-table-column label="渠道类型" prop="platform" align="center">
          <template #default="{ row }">
            <span>{{ getCanal(row.platform) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="域名" prop="url" align="center" /> -->
        <el-table-column
          label="落地页类型"
          prop="loadingPageType"
          align="center"
        >
          <template #default="{ row }">
            <span>{{ getPlatform(row.loadingPageType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="小红书广告主id"
          prop="xhsAccountId"
          align="center"
        />
        <el-table-column
          label="数据推送Token"
          prop="xhsDataPushToken"
          align="center"
        />
        <el-table-column label="创建时间" prop="created_at" align="center" />
        <el-table-column label="操作" width="250" align="center" fixed="right">
          <template #default="{ row }">
            <div style="display: inline-block; margin-right: 10px">
              <loading-page-add
                :updateLoadingPageData="row"
                @updateList="getList"
              >
                <el-button type="success">
                  {{ $t('msg.button.uploadLoadingPage') }}
                </el-button>
              </loading-page-add>
            </div>
            <div style="display: inline-block; margin-right: 10px">
              <el-button type="primary" @click="handleFormClick(row)">
                {{ $t('msg.button.seeForm') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { setItem } from '@/utils/storage'
import { getLoadingPage } from '@/api/loadingPage'
import LoadingPageAdd from './components/LoadingPageAdd'
export default {
  components: {
    LoadingPageAdd
  },
  data() {
    return {
      listQuery: {
        status: 1
      },
      tableKey: 0,
      list: [],
      listLoading: false,
      defaultSort: {},
      total: 0,
      pageStatusOptions: [
        {
          value: 0,
          label: '禁用'
        },
        {
          value: 1,
          label: '启用'
        },
        {
          value: 3,
          label: '全部'
        }
      ],
      projectFormData: {}
    }
  },
  computed: {
    getCanal() {
      return function (type) {
        switch (type) {
          case '3':
            return '小红书'
          default:
            return ''
        }
      }
    },
    getPlatform() {
      return function (loadingPageType) {
        switch (loadingPageType) {
          case '1':
            return '自建站'
          case '2':
            return '外链'
          default:
            return '外链'
        }
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handlerFilter() {},
    async getList() {
      const res = await getLoadingPage()
      this.list = res
    },
    sortChange(data) {
      const { prop, order } = data
      this.sortBy(prop, order)
    },
    sortBy(prop, order) {
      if (order === 'ascending') {
        this.listQuery.sort = `+${prop}`
      } else {
        this.listQuery.sort = `-${prop}`
      }
      this.handlerFilter()
    },
    handleFormClick(row) {
      // 查看表单
      this.$store.dispatch('loadingForm/setId', row.id)
      this.$store.dispatch('loadingForm/setXhsAccountId', row.xhsAccountId)
      setItem('P_ID', row.id)
      setItem('XHS_ACCOUNT_ID', row.xhsAccountId)
      this.$router.push({
        path: '/formManager/manage',
        query: {
          id: row.id,
          xhsAccountId: row.xhsAccountId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loadingPage-container {
  .header {
    margin-bottom: 22px;
    .filter-container {
      display: flex;
      align-items: center;
      .filter-item {
        .search-icon {
          fill: #fff;
        }
      }
    }
  }
}
</style>
