import { TOKEN, TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant'
import { getItem, setItem, removeItem } from '@/utils/storage'

export const getToken = () => {
  return getItem(TOKEN)
}
export const setToken = (token) => {
  return setItem(TOKEN, token)
}
export const removeToken = () => {
  return removeItem(TOKEN)
}

/** 获取时间戳 */
export const getTimeStamp = () => {
  return getItem(TIME_STAMP)
}

/** 设置时间戳 */
export const setTimeStamp = () => {
  setItem(TIME_STAMP, Date.now())
}

/** 是否超时 */
export const isCheckTimeout = () => {
  const currentTime = Date.now()
  const timeStamp = getTimeStamp()
  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE
}
