<template>
  <div class="login-container">
    <el-form
      class="login-form"
      :model="loginForm"
      :rules="loginRules"
      ref="loginFormRef"
    >
      <div class="title-container">
        <h3 class="title">{{ $t('msg.login.title') }}</h3>
        <!-- <lang-select class="lang-select" /> -->
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon="user"></svg-icon>
        </span>
        <el-input
          placeholder="username"
          name="username"
          type="text"
          tabindex="1"
          v-model="loginForm.username"
        />
      </el-form-item>

      <el-tooltip
        :visible="capsTooltip"
        content="Caps lock is On"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <el-icon>
              <svg-icon icon="password"></svg-icon>
            </el-icon>
          </span>
          <el-input
            placeholder="password"
            name="password"
            :type="passwordType"
            tabindex="2"
            v-model="loginForm.password"
            @keyup="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter="handleLogin"
          />
          <span class="show-pwd">
            <el-icon>
              <svg-icon
                :icon="passwordType === 'password' ? 'eye' : 'eye-open'"
                @click="handleChangePwdType"
              ></svg-icon>
            </el-icon>
          </span>
        </el-form-item>
      </el-tooltip>

      <el-button
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        :loading="loading"
        @click="handleLogin"
        >{{ $t('msg.login.loginBtn') }}</el-button
      >
    </el-form>
  </div>
</template>

<script setup>
// import LangSelect from '@/components/LangSelect'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRouteQuery } from '@/hooks/useRouteQuery'
import { validatePassword } from './rules'
import { useI18n } from 'vue-i18n'
// 数据源
const loginForm = ref({
  username: 'admin',
  password: '112233..'
})
const { t } = useI18n()
const checkUsernameRule = computed(() => {
  return t('msg.login.usernameRule')
})
// 验证规则
const loginRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      message: checkUsernameRule
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})
// 处理密码框文本显示状态
const passwordType = ref('password')
const handleChangePwdType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}
// 大小写提示
const capsTooltip = ref(false)
const checkCapslock = (e) => {
  const { key } = e
  capsTooltip.value = key && key.length === 1 && key >= 'A' && key <= 'Z'
}
// 登录动作处理
const router = useRouter()
const { redirect, otherQuery } = useRouteQuery()
const loading = ref(false)
const loginFormRef = ref(null)
const store = useStore()
const handleLogin = () => {
  loginFormRef.value.validate((valid) => {
    if (!valid) {
      return false
    }
    loading.value = true
    store
      .dispatch('user/login', loginForm.value)
      .then(() => {
        loading.value = false
        if (redirect.value === '/') {
          redirect.value = '/home'
        }
        router.push({
          path: redirect.value || '/home',
          query: otherQuery.value
        })
      })
      .catch(() => {
        loading.value = false
      })
  })
}
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #fff;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    :deep(.el-form-item) {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }

    :deep(.el-input) {
      display: inline-block;
      height: 47px;
      width: 85%;

      input {
        background: transparent;
        border: 0px;
        appearance: none;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        caret-color: $cursor;
      }
    }

    :deep(.el-input__wrapper) {
      padding: 0;
      .el-input__inner {
        padding: 6px 5px 6px 15px !important;
      }
    }

    :deep(.el-input__inner:-webkit-autofill),
    :deep(.el-input__inner:-webkit-autofill:hover),
    :deep(.el-input__inner:-webkit-autofill:focus),
    :deep(.el-input__inner:-webkit-autofill:active) {
      -webkit-transition-delay: 99999s;
      -webkit-transition: color 99999s ease-out,
        background-color 99999s ease-out;
    }

    :deep(.el-input__wrapper) {
      background: none !important;
      box-shadow: none !important;
      --el-select-input-focus-border-color: none !important;
    }
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    box-sizing: border-box;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .lang-select {
    position: absolute;
    top: 4px;
    right: 0;
    background-color: #fff;
    font-size: 22px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
