import request from '@/utils/request'

export const createLoadingPage = (data) => {
  return request({
    url: '/loadingPage/create',
    method: 'post',
    data
  })
}

export const getLoadingPage = () => {
  return request({
    url: '/loadingPage',
    method: 'get'
  })
}

export const uploadLoadingPage = (data) => {
  return request({
    url: '/loadingPage/edit',
    method: 'post',
    data
  })
}
