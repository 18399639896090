<template>
  <div class="formManager-container">
    <el-card class="header">
      <div class="tip-continaer">
        <span style="display: block; margin-bottom: 10px"
          >小红书账号id：{{ xhsAccountIdComputed }}</span
        >
        <span>pageId: {{ idComputed }}</span>
      </div>
      <div class="filter-container"></div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <!-- <el-table-column label="落地页ID" prop="pId" align="center" />
        <el-table-column label="落地页url" prop="url" align="center" />
        <el-table-column label="广告类型" prop="sourceChannel" align="center" />
        <el-table-column label="广告主ID" prop="accountId" align="center" />
        <el-table-column label="广告主名称" prop="accountName" align="center" /> -->
        <el-table-column label="广告计划id" prop="campaignId" align="center" />
        <!-- <el-table-column label="广告计划" prop="campaignName" align="center" /> -->
        <el-table-column label="广告单元" prop="unitId" align="center" />
        <!-- <el-table-column label="广告单元名称" prop="unitName" align="center" /> -->
        <el-table-column label="广告创意 ID" prop="leadsId" align="center" />
        <el-table-column
          label="唯一标识一条线索"
          prop="creativeId"
          align="center"
        />
        <!-- <el-table-column label="IP" prop="ip" align="center" /> -->
        <el-table-column label="姓名" prop="name" align="center" />
        <el-table-column label="电话" prop="phone" align="center" />
        <el-table-column label="省份" prop="province" align="center" />
        <el-table-column label="城市" prop="city" align="center" />
        <el-table-column label="详细地址" prop="address" align="center" />
        <el-table-column label="性别" prop="gender" align="center" />
        <el-table-column label="表单提交时间" prop="timestamp" align="center">
          <template #default="{ row }">
            <span>{{ formatTimestampFn(row.timestamp) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="推送来源" prop="source" align="center" />
        <el-table-column label="关键词" prop="source" align="center" />
        <el-table-column label="提交转化的时间" prop="trackTime" align="center">
          <template #default="{ row }">
            <span>{{
              row.trackTime ? formatTimestampFn(row.trackTime) : '未提交'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template #default="{ row }">
            <el-button
              :disabled="row.isConvert ? true : false"
              type="success"
              @click="handlePut(row)"
              >{{
                row.isConvert ? $t('msg.button.isPut') : $t('msg.button.put')
              }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage'
import { getLoadingPageForm, postTrack } from '@/api/loadingPageForm'
import { formatTimestamp } from '@/utils/timeFormat'
export default {
  data() {
    return {
      pid: 0,
      xhsAccountId: '',
      tableKey: 0,
      list: [],
      listLoading: false
    }
  },
  created() {
    // 从 localStorage 中获取参数
    const pid = getItem('P_ID')
    const xhsAccountId = getItem('XHS_ACCOUNT_ID')
    // 如果 localStorage 中存在 xhsAccountId，将其设置到 Vuex 中
    if (pid) {
      this.$store.dispatch('loadingForm/setId', pid)
      this.pid = pid
    } else {
      this.$router.push('/loadingPage/manage')
      return
    }
    if (xhsAccountId) {
      this.$store.dispatch('loadingForm/setXhsAccountId', xhsAccountId)
      this.xhsAccountId = xhsAccountId
    } else {
      this.$router.push('/loadingPage/manage')
      return
    }
    this.getLoadingPageForm(xhsAccountId)
  },
  computed: {
    idComputed() {
      return this.$store.state.loadingForm.id
    },
    xhsAccountIdComputed() {
      return this.$store.state.loadingForm.xhsAccountId
    }
  },
  methods: {
    formatTimestampFn(time) {
      return formatTimestamp(time)
    },
    async getLoadingPageForm(xhsAccountId) {
      const data = await getLoadingPageForm(xhsAccountId)
      this.list = data
    },
    async handlePut(row) {
      const { id, campaignId, unitId, creativeId, leadsId } = row
      // 提交转化
      const pid = this.pid // 落地页id
      const data = {
        id, // 表单记录id
        pid,
        campaignId,
        unitId,
        creativeId,
        leadsId
      }
      await postTrack(data)
      this.$notify({
        title: '成功',
        message: '提交成功',
        type: 'success',
        duration: 2000
      })
      this.getLoadingPageForm(this.xhsAccountId)
    }
  }
}
</script>

<style lang="scss" scoped>
.formManager-container {
  .header {
    margin-bottom: 22px;
  }
}
</style>
