<template>
  <div class="menu">
    <el-dialog
      :title="updateMenuData ? '编辑菜单' : '新增菜单'"
      v-model="dialogVisible"
      width="60%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="menuFormRef"
        :model="menuForm"
        :label-position="labelPosition"
        label-width="80px"
        status-icon
        :rules="rules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="menuForm.name" placeholder="请输入菜单" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="URL" prop="url">
              <el-input v-model="menuForm.url" placeholder="请输入菜单url" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="父菜单" prop="pid">
              <el-select
                v-model="menuForm.pid"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in pidData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="menuForm.sort" placeholder="请输入排序顺序" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="重定向" prop="redirect">
              <el-input
                v-model="menuForm.redirect"
                placeholder="请输入重定向地址"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="icon">
              <el-input v-model="menuForm.icon" placeholder="请输入菜单图标" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="是否隐藏" prop="hidden">
              <el-select
                v-model="menuForm.hidden"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in hiddenData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button @click="handleAddMenu" type="primary">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { getMenuList, updateMenu, addMenu } from '@/api/menu'
import { ref, reactive } from 'vue'
import { ElNotification } from 'element-plus'

const fileds = {
  name: '菜单名称',
  url: 'URL',
  pid: '父菜单',
  sort: '排序'
}

const hiddenData = [
  {
    label: '隐藏',
    value: 1
  },
  {
    label: '显示',
    value: 0
  }
]

// eslint-disable-next-line
const props = defineProps(['addMenuData', 'updateMenuData', 'listQuery'])

const menuFormRef = ref(null)
const dialogVisible = ref(false)
const labelPosition = ref('left')
let menuForm = reactive({
  name: '',
  url: '',
  pid: 0,
  sort: 1,
  redirect: '',
  icon: '',
  hidden: 0,
  isDeleted: 0
})
const pidData = ref([])
const addPid = ref(0)

// 校验规则
const validateRequire = (rule, value, callback) => {
  if (!value) {
    callback(new Error(fileds[rule.field] + '必须填写'))
  } else {
    callback()
  }
}
let rules = reactive({
  name: [{ required: true, validator: validateRequire }],
  url: [{ required: true, validator: validateRequire }],
  pid: [{ required: true, message: '父菜单选项不能为空', trigger: ['blur'] }],
  sort: [{ required: true, validator: validateRequire }]
})

const openDialog = () => {
  init()
  dialogVisible.value = true
}
const handleClose = () => {
  dialogVisible.value = false
  menuFormRef.value.resetFields()
}
const init = () => {
  getList()
  menuForm.sort = 1
  if (props.updateMenuData) {
    let newMenuData = JSON.parse(JSON.stringify(props.updateMenuData))
    if (newMenuData === 0) {
      newMenuData = '顶级菜单'
    }
    menuForm = newMenuData
    menuForm.id = newMenuData.id
  }
  if (props.addMenuData) {
    let newMenuData = JSON.parse(JSON.stringify(props.addMenuData))
    if (newMenuData === 0) {
      newMenuData = '顶级菜单'
    }
    menuForm.pid = newMenuData.name
    addPid.value = newMenuData.id
  }
}

const handleAddMenu = () => {
  submitForm()
}

const submitForm = () => {
  const newRule = [...rules.pid, { required: true, trigger: 'change' }]
  rules = Object.assign({}, rules, { pid: newRule })
  menuFormRef.value.validate((valid) => {
    if (valid) {
      addOrUddateMenu()
    } else {
      return false
    }
  })
}

const addOrUddateMenu = async () => {
  const { sort, pid, name, url, id, redirect, icon, hidden } = menuForm
  let newParentId
  if (props.addMenuData) {
    newParentId = addPid.value
  } else {
    if (pid === '顶级菜单') {
      newParentId = 0
    } else {
      newParentId = pid
    }
  }
  const newQuery = {
    pid: newParentId,
    name,
    url,
    sort,
    redirect,
    icon,
    hidden
  }
  if (props.updateMenuData) {
    newQuery.id = id
    await updateMenu(newQuery).then((res) => {
      ElNotification({
        title: '成功',
        message: res.message || '更新菜单成功',
        type: 'success',
        duration: 2000
      })
    })
  } else {
    await addMenu(newQuery).then((res) => {
      ElNotification({
        title: '成功',
        message: res.message || '添加菜单成功',
        type: 'success',
        duration: 2000
      })
    })
  }
  menuFormRef.value.resetFields()
  // eslint-disable-next-line
  emits('updateList')
  refreshPage()
  dialogVisible.value = false
}

const getList = () => {
  getMenuList(props.listQuery).then((res) => {
    pidData.value = res
    pidData.value.unshift({ id: 0, name: '顶级菜单', pid: 0 })
  })
}

const refreshPage = () => {
  // 使用 attrs.$router.go(0) 方法刷新页面
  // eslint-disable-next-line
  attrs.$router.go(0)
}
</script>

<style lang="scss" scoped></style>
