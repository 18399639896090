import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
export const useRouteQuery = () => {
  const route = useRoute()
  const redirect = ref('') // 重定向的路径 也就是原来想访问的路径
  const otherQuery = ref({}) // 其它的查询参数
  const getOtherQuery = (query) => {
    const { redirect, ...other } = query // 查询参数对象
    return other
  }
  const redirectComputed = computed(() => route.query.redirect || '')
  const otherQueryComputed = computed(() => getOtherQuery(route.query))
  watchEffect(() => {
    otherQuery.value = otherQueryComputed.value
    redirect.value = redirectComputed.value
  })
  return {
    redirect,
    otherQuery
  }
}
