<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script setup>
import { computed } from 'vue'
import { isExternal } from '@/utils/validate'
// eslint-disable-next-line
const props = defineProps(['to'])

const isExternalLink = isExternal(props.to)

const type = computed(() => (isExternalLink ? 'a' : 'router-link'))

const linkProps = (to) => {
  if (isExternalLink) {
    return {
      href: to,
      target: '_blank',
      rel: 'noopener'
    }
  }
  return {
    to: to
  }
}
</script>
