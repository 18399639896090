export default {
  login: {
    title: '用户登录',
    loginBtn: '登录',
    usernameRule: '用户名为必填项',
    passwordRule: '密码不能少于6位'
  },
  logoTitle: {
    title: '皓思网络'
  },
  navBar: {
    themeChange: '主题修改',
    headerSearch: '页面搜索',
    screenfull: '全屏替换',
    lang: '国际化',
    guide: '功能引导',
    home: '首页',
    course: '课程主页',
    logout: '退出登录'
  },
  guide: {
    close: '关闭',
    next: '下一个',
    prev: '上一个',
    guideTitle: '引导',
    guideDesc: '打开引导功能',
    hamburgerTitle: '汉堡按钮',
    hamburgerDesc: '打开和关闭左侧菜单',
    breadcrumbTitle: '面包屑',
    breadcrumbDesc: '指示当前页面位置',
    searchTitle: '搜索',
    searchDesc: '页面链接搜索',
    fullTitle: '全屏',
    fullDesc: '页面显示切换',
    themeTitle: '主题',
    themeDesc: '更换项目主题',
    langTitle: '国际化',
    langDesc: '语言切换',
    tagTitle: '标签',
    tagDesc: '已打开页面标签',
    sidebarTitle: '菜单',
    sidebarDesc: '项目功能菜单'
  },
  toast: {
    switchLangSuccess: '切换语言成功'
  },
  tagsView: {
    refresh: '刷新',
    closeRight: '关闭右侧',
    closeOther: '关闭其他'
  },
  route: {
    home: '主页',
    home_P: '主页',
    authority: '权限管理',
    user: '用户管理',
    role: '角色管理',
    menu: '菜单管理',
    loadingPage: '落地页管理',
    loadingPage_P: '落地页管理',
    material: '素材库',
    newMaterialStore: '素材库',
    customer: '客服管理',
    add: '添加客服',
    group: '客服组',
    formManager_P: '表单管理',
    formManager: '表单管理'
  },
  theme: {
    themeColorChange: '主题色更换',
    themeChange: '主题更换'
  },
  universal: {
    confirm: '确定',
    cancel: '取消'
  },
  button: {
    add: '新增',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    sure: '确认',
    search: '查询',
    login: '一键登录',
    addMaterial: '添加落地页素材',
    addType: '添加落地页类型',
    addCustomerGroup: '添加客服组',
    addProjectType: '添加项目类型',
    addLoadingPage: '添加落地页',
    uploadLoadingPage: '修改落地页',
    seeForm: '查看表单',
    put: '提交',
    isPut: '已提交'
  },
  filter: {
    name: '姓名',
    nickname: '昵称',
    email: '邮箱',
    role: '角色',
    type: '落地页类型',
    classify: '分类',
    url: '域名',
    customerGroup: '客服组',
    groupName: '请输入要搜索的客服组'
  }
}
