<template>
  <div class="addUser">
    <el-dialog
      :title="userData ? '编辑用户' : '新增用户'"
      v-model="dialogVisible"
      width="60%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="formRef"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
        status-icon
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="用户姓名" prop="username">
              <el-input
                v-model="form.username"
                placeholder="请输入用户姓名"
                autocomplete="new-username"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户密码" prop="password">
              <el-input
                v-model="form.password"
                show-password
                type="password"
                placeholder="请输入用户密码"
                autocomplete="new-password"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="昵称" prop="nickname">
              <el-input v-model="form.nickname" placeholder="请输入昵称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户邮箱" prop="email">
              <el-input
                v-model="form.email"
                type="email"
                placeholder="请输入用户邮箱"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="用户头像">
              <div v-if="!form.avatar && userData" style="width: 100%">
                暂无用户头像
              </div>
              <el-upload
                ref="uploadRef"
                :headers="headers"
                :action="action"
                name="avatar"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :limit="1"
                :file-list="fileList"
                accept="png/jpg/jpeg/gif"
                :before-upload="beforeUpload"
                :on-exceed="onExceed"
                :on-error="onError"
                :on-success="success"
              >
                <svg-icon icon="add" class="avatar-add" />
              </el-upload>
              <img
                v-show="dialogImageUrl"
                width="50%"
                :src="dialogImageUrl"
                alt=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色" prop="role">
              <el-select
                v-model="form.role"
                multiple
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in roleData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog" style="text-align: right">
        <el-button @click="handleClose">{{
          $t('msg.button.cancel')
        }}</el-button>
        <el-button @click="handleAddRole" type="primary">{{
          $t('msg.button.sure')
        }}</el-button>
      </div>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { addUser, editUser } from '@/api/user'
const fields = {
  username: '用户名',
  password: '密码',
  role: '角色'
}
export default {
  props: ['userData', 'roleList'],
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value) {
        callback(new Error(fields[rule.field] + '必须填写'))
      } else if (rule.field === 'password' && value.length < 6) {
        callback(new Error('密码至少6个字符'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {},
      labelPosition: 'left',
      rules: {
        username: [{ required: true, validator: validateRequire }],
        password: [{ required: true, validator: validateRequire }],
        role: [{ required: true, validator: validateRequire }]
      },
      action: 'http://localhost:5000/v1/user/avatar',
      fileList: [],
      dialogImageUrl: '',
      oldAvatar: false, // 上传时展示的图片
      roleData: []
    }
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${getToken()}`
      }
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
      this.init()
    },
    init() {
      this.roleData = this.roleList
      if (this.userData) {
        const oldData = JSON.parse(JSON.stringify(this.userData))
        if (typeof oldData.role !== 'object') {
          if (typeof oldData.role === 'string') {
            if (oldData.role.indexOf(',') !== -1) {
              oldData.role = oldData.role.split(',')
            } else {
              oldData.role = [oldData.role]
            }
          }
        }
        this.form = oldData
        if (this.$refs.uploadRef) {
          // 清空上传的图片
          this.$refs.uploadRef.clearFiles()
        }
        if (this.form.avatar) {
          this.fileList = []
          const newUrl = { url: oldData.avatar }
          this.fileList.push(newUrl)
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.dialogImageUrl = false
      this.$refs.formRef.resetFields()
      if (this.$refs.uploadRef) {
        // 清空上传图片
        this.$refs.uploadRef.clearFiles()
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove() {
      this.dialogImageUrl = ''
      this.form.avatar = ''
    },
    beforeUpload(file) {
      const uploadType = file.name.substring(file.name.lastIndexOf('.') + 1)
      const imgType = uploadType === 'jpg'
      const imgType1 = uploadType === 'jpeg'
      const imgType2 = uploadType === 'gif'
      const imgType3 = uploadType === 'png'
      if (!imgType && !imgType1 && !imgType2 && !imgType3) {
        this.$message({
          message: '头像只能是jpg,jpeg,gif格式',
          type: 'warning'
        })
      }
      this.oldAvatar = false
      return imgType || imgType1 || imgType2 || imgType3
    },
    onExceed() {
      this.$message({
        message: '每次只能上传一张图片',
        type: 'warning'
      })
    },
    success(file) {
      this.form.avatar = file.data
    },
    onError(err) {
      console.log(err.message)
      // const errMsg = err.message && JSON.parse(err.message)
      // this.$message({
      //   message:
      //     (errMsg && errMsg.msg && `上传失败，失败原因：${errMsg.msg}`) ||
      //     '上传失败',
      //   type: 'error'
      // })
      this.$emit('onError', err)
    },
    handleAddRole() {
      this.submitForm('formRef')
    },
    submitForm(formName) {
      const newRule = [
        ...this.rules.role,
        { required: true, trigger: 'change' }
      ]
      this.rules = Object.assign({}, this.rules, { role: newRule })
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addOrUpdateUser()
        } else {
          return false
        }
      })
    },
    async addOrUpdateUser() {
      let oldRole = ''
      this.form.role.map((item) => {
        oldRole += `,${item}`
      })
      const newRole = oldRole.replace(',', '')
      const newUser = Object.assign({}, this.form, { role: newRole })
      if (this.userData) {
        await editUser(newUser)
        this.$notify({
          title: '成功',
          message: '更新用户成功',
          type: 'success',
          duration: 2000
        })
      } else {
        await addUser(newUser)
        this.$notify({
          title: '成功',
          message: '添加用户成功',
          type: 'success',
          duration: 2000
        })
      }
      this.$refs.formRef.resetFields()
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles()
      }
      this.dialogVisible = false
      this.dialogImageUrl = false
      this.$emit('updateList')
    }
  }
}
</script>

<style scoped>
.avatar-add {
  cursor: pointer;
  font-size: 22px;
  vertical-align: middle;
}
</style>
