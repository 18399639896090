<template>
  <div class="kefu-container">
    <el-card class="header">
      <div class="filter-container">
        <el-select
          v-model="listQuery.group"
          placeholder="所有分组"
          style="width: 200px; margin-right: 10px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in allGroupList"
            :key="item.id"
            :label="item.groupName"
            :value="item.id"
          />
        </el-select>
        <el-input
          v-model="listQuery.groupNameOrWechat"
          placeholder="搜索分组名/微信号"
          style="width: 200px; margin-right: 10px"
        />
        <el-button class="filter-item" @click="handlerFilter" type="primary">
          <svg-icon icon="search" class-name="search-icon"></svg-icon>
        </el-button>
        <div style="display: inline-block; margin-left: auto">
          <add-kefu @updateList="getList" :group-list="allGroupList">
            <el-button class="filter-item" type="primary">添加客服</el-button>
          </add-kefu>
        </div>
      </div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="kefuList"
        border
        fit
        style="width: 100%"
        @sort-change="sortChange"
        align="center"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <el-table-column label="姓名" align="center" prop="name" />
        <el-table-column label="微信" align="center" prop="weixin">
          <template #default="{ row: { weixinWrapper } }">
            <span v-html="weixinWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="手机" align="center" prop="phone" />
        <el-table-column label="二维码" align="center" prop="qrcode">
          <template #default="{ row }">
            <el-image
              :scr="row.qrcode"
              :preview-src-list="imageList"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="QQ" align="center" prop="qq" />
        <el-table-column label="性别" align="center" prop="sex" />
        <el-table-column label="客服组" align="center" prop="groupName" />
        <el-table-column label="操作" align="center" fixed="right">
          <template>
            <el-button type="success">
              {{ $t('msg.button.edit') }}
            </el-button>
            <el-button type="danger" style="margin: 0 10px">
              {{ $t('msg.button.delte') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <Pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="refresh"
    />
  </div>
</template>

<script>
import { getGroup, getKefuList } from '@/api/customer'
import Pagination from '@/components/Pagination'
import AddKefu from './components/addKefu'
export default {
  components: {
    Pagination,
    AddKefu
  },
  data() {
    return {
      tableKey: 0,
      userId: this.$store.getters.userInfo.id,
      listLoading: false,
      listQuery: {},
      defaultSort: {},
      total: 0,
      allGroupList: [],
      kefuList: [],
      imageList: []
    }
  },
  created() {
    this.parseQuery()
  },
  mounted() {
    this.getGroupFn()
    this.getList()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      const newQuery = Object.assign({}, to.query)
      const oldQuery = Object.assign({}, from.query)
      if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
        this.getList()
      }
    }
    next()
  },
  methods: {
    parseQuery() {
      const query = Object.assign({}, this.$route.query)
      let sort = '+id'
      const listQuery = {
        userId: this.userId,
        page: 1,
        pageSize: 20,
        sort,
        isDeleted: 0
      }
      if (query) {
        query.userId && (query.userId = +query.userId)
        query.page && (query.page = +query.page)
        query.pageSize && (query.pageSize = +query.pageSize)
        query.sort && (sort = query.sort)
        query.group && (query.group = +query.group)
        query.isDeleted && (query.isDeleted = +query.isDeleted)
      }
      const sortSymbol = sort[0]
      const sortColumn = sort.slice(1, sort.length)
      this.defaultSort = {
        prop: sortColumn,
        order: sortSymbol === '+' ? 'ascending' : 'descending'
      }
      this.listQuery = { ...listQuery, ...query }
    },
    sortChange(data) {
      const { prop, order } = data
      this.sortBy(prop, order)
    },
    sortBy(prop, order) {
      if (order === 'ascending') {
        this.listQuery.sort = `+${prop}`
      } else {
        this.listQuery.sort = `-${prop}`
      }
      this.handlerFilter()
    },
    wrapperKeyword(k, v) {
      function highlight(value) {
        return `<span style="color: #1890ff">${value}</span>`
      }
      if (!this.listQuery[k]) {
        return v
      } else {
        return v.replace(new RegExp(this.listQuery[k], 'ig'), (v) =>
          highlight(v)
        )
      }
    },
    handlerFilter() {
      this.listQuery.page = 1
      this.refresh()
    },
    refresh() {
      this.$router.push({
        path: '/customer/add',
        query: this.listQuery
      })
    },
    async getGroupFn() {
      const list = await getGroup({ userId: this.userId })
      this.allGroupList = list
    },
    getList() {
      this.listLoading = true
      getKefuList(this.listQuery)
        .then((data) => {
          this.listLoading = false
          const { list, total } = data
          this.kefuList = list
          this.total = total
          this.kefuList.forEach((kefu) => {
            this.imageList.push(kefu.qrcode)
            kefu.weixinWrapper = this.wrapperKeyword(
              'groupNameOrWechat',
              kefu.weixin
            )
          })
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.kefu-container {
  .header {
    margin-bottom: 22px;
    .filter-container {
      display: flex;
      align-items: center;
      .search-icon {
        fill: #fff;
      }
    }
  }
}
</style>
