import request from '@/utils/request'

/**
 * 获取用户菜单
 */
export const getUserMenu = (params) => {
  let roles = params.roles
  if (Array.isArray(roles) && roles.length > 0) {
    roles = roles.join(',')
  }
  return request({
    url: `/menu/role?roles=${roles}`,
    method: 'get'
  })
}

/**
 * 获取所有菜单
 */
export const getMenuList = () => {
  return request({
    url: '/menu',
    method: 'get'
  })
}

/**
 * 更新菜单
 */
export const updateMenu = (data) => {
  return request({
    url: '/menu',
    method: 'put',
    data
  })
}

/**
 * 新增菜单
 */
export const addMenu = (data) => {
  return request({
    url: '/menu',
    method: 'post',
    data
  })
}

/**
 * 删除菜单
 */
export const deleteMenu = (data) => {
  return request({
    url: '/menu',
    method: 'delete',
    data
  })
}
