<template>
  <div class="role-container">
    <el-card class="header">
      <div style="display: inline-block">
        <role-form-dialog @updateList="getList">
          <el-button class="filter-item" type="primary">{{
            $t('msg.button.add')
          }}</el-button>
        </role-form-dialog>
      </div>
    </el-card>
    <el-card>
      <el-table
        :data="roleData"
        row-key="id"
        border
        :default-sort="defaultSort"
        @sort-change="sortChange"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
        />
        <el-table-column label="角色名称" prop="name" align="center" />
        <el-table-column label="角色级别" prop="level" align="center" />
        <el-table-column label="操作" width="320" align="center" fixed="right">
          <template #default="{ row }">
            <div style="display: inline-block; margin: 0 10px">
              <role-form-dialog :update-role-data="row" @updateList="getList">
                <el-button type="success">{{
                  $t('msg.button.edit')
                }}</el-button>
              </role-form-dialog>
            </div>
            <el-button type="danger" @click="handleDelete(row)">
              {{ $t('msg.button.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getRoleList, deleteRole } from '@/api/role'
import RoleFormDialog from './components/RoleAdd.vue'
export default {
  name: 'Role',
  components: {
    RoleFormDialog
  },
  data() {
    return {
      roleData: [],
      defaultSort: {},
      listQuery: {}
    }
  },
  created() {
    this.parseQuery()
  },
  mounted() {
    this.getList()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      const oldQuery = Object.assign({}, from.query)
      const newQuery = Object.assign({}, to.query)
      if (JSON.stringify(oldQuery) !== JSON.stringify(newQuery)) {
        this.getList()
      }
    }
    next()
  },
  methods: {
    parseQuery() {
      const query = Object.assign({}, this.$route.query)
      let sort = '+id'
      if (query) {
        query.sort && (sort = query.sort)
      }
      const listQuery = {
        sort
      }
      const sortSymbol = sort[0]
      const sortColumn = sort.slice(1, sort.length)
      this.defaultSort = {
        prop: sortColumn,
        order: sortSymbol === '+' ? 'ascending' : 'descending'
      }
      this.listQuery = { ...listQuery, ...query }
    },
    sortChange(data) {
      const { prop, order } = data
      this.sortBy(prop, order)
    },
    sortBy(prop, order) {
      if (order === 'ascending') {
        this.listQuery.sort = `+${prop}`
      } else {
        this.listQuery.sort = `-${prop}`
      }
      this.handlerFilter()
    },
    handlerFilter() {
      this.refresh()
    },
    refresh() {
      this.$router.push({
        path: '/authority/role',
        query: this.listQuery
      })
    },
    handleDelete(data) {
      this.$confirm('此操作将永久删角色，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole(data).then((result) => {
          this.$notify({
            title: '成功',
            message: result.msg || '删除成功',
            type: 'success',
            duration: 2000
          })
          this.getList()
        })
      })
    },
    getList() {
      getRoleList(this.listQuery).then((data) => {
        this.roleData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.role-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
}
</style>
