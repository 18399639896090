<template>
  <div class="group-container">
    <el-card class="header">
      <div class="filter-container">
        <!-- <span>客服组：</span> -->
        <!-- <el-select
          v-model="listQuery.groupId"
          :placeholder="$t('msg.filter.customerGroup')"
          style="width: 200px; margin-right: 10px"
          class="filter-item"
          filterable
          clearable
        >
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.group_name"
            :value="item.id"
          />
        </el-select> -->
        <span>客服组名称：</span>
        <el-input
          v-model="listQuery.groupName"
          :placeholder="$t('msg.filter.groupName')"
          style="width: 200px; margin-right: 10px"
          class="filter-item"
        />
        <span>客服组状态：</span>
        <el-select
          v-model="listQuery.is_deleted"
          placeholder="客服组状态"
          style="width: 100px; margin-right: 10px"
        >
          <el-option
            v-for="item in groupStatusOptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button class="filter-item" @click="handlerFilter" type="primary">
          <svg-icon icon="search" class-name="search-icon"></svg-icon>
        </el-button>
        <div style="display: inline-block; margin-left: auto">
          <group-add @uploadGroup="getList">
            <el-button class="filter-item" type="primary">{{
              $t('msg.button.addCustomerGroup')
            }}</el-button>
          </group-add>
        </div>
      </div>
    </el-card>
    <el-card>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="groupList"
        border
        fit
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          label="ID"
          prop="id"
          sortable="custom"
          align="center"
          width="80"
        />
        <el-table-column label="客服组" align="center">
          <template #default="{ row: { groupNameWrapper } }">
            <span v-html="groupNameWrapper" />
          </template>
        </el-table-column>
        <el-table-column label="客服组状态" align="center">
          <template #default="{ row }">
            <el-switch
              v-model="row.isDeleted"
              :active-value="0"
              :inactive-value="1"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
            />
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" fixed="right">
          <template #default="{ row }">
            <div style="display: inline-block">
              {{ row }}
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>
    <Pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="refresh"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import GroupAdd from './components/GroupAdd.vue'
import { getGroupList } from '@/api/customer'
export default {
  components: {
    Pagination,
    GroupAdd
  },
  data() {
    return {
      tableKey: 0,
      listLoading: false,
      listQuery: {},
      groupList: [],
      defaultSort: {},
      total: 0,
      groupStatusOptions: [
        {
          id: 1,
          label: '开启',
          value: 0
        },
        {
          id: 2,
          label: '关闭',
          value: 1
        }
      ]
    }
  },
  created() {
    this.parseQuery()
  },
  mounted() {
    this.getList()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      const newQuery = Object.assign({}, to.query)
      const oldQuery = Object.assign({}, from.query)
      if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
        this.getList()
      }
    }
    next()
  },
  methods: {
    parseQuery() {
      const query = Object.assign({}, this.$route.query)
      let sort = '+id'
      const listQuery = {
        userId: this.$store.getters.userInfo.id,
        page: 1,
        pageSize: 20,
        sort,
        is_deleted: 0
      }
      if (query) {
        query.page && (query.page = +query.page)
        query.pageSize && (query.pageSize = +query.pageSize)
        query.sort && (sort = query.sort)
        query.is_deleted && (query.is_deleted = +query.is_deleted)
      }
      const sortSymbol = sort[0]
      const sortColumn = sort.slice(1, sort.length)
      this.defaultSort = {
        prop: sortColumn,
        order: sortSymbol === '+' ? 'ascending' : 'descending'
      }
      this.listQuery = { ...listQuery, ...query }
    },
    sortChange(data) {
      const { prop, order } = data
      this.sortBy(prop, order)
    },
    sortBy(prop, order) {
      if (order === 'ascending') {
        this.listQuery.sort = `+${prop}`
      } else {
        this.listQuery.sort = `-${prop}`
      }
      this.handlerFilter()
    },
    wrapperKeyword(k, v) {
      function highlight(value) {
        return `<span style="color: #1890ff">${value}</span>`
      }
      if (!this.listQuery[k]) {
        return v
      } else {
        return v.replace(new RegExp(this.listQuery[k], 'ig'), (v) =>
          highlight(v)
        )
      }
    },
    handlerFilter() {
      this.listQuery.page = 1
      this.refresh()
    },
    refresh() {
      this.$router.push({
        path: '/customer/group',
        query: this.listQuery
      })
    },
    getList() {
      this.listLoading = true
      getGroupList(this.listQuery)
        .then((data) => {
          this.listLoading = false
          const { list, total } = data
          this.groupList = list
          this.total = total
          this.groupList.forEach((group) => {
            group.groupNameWrapper = this.wrapperKeyword(
              'groupName',
              group.groupName
            )
          })
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-container {
  .header {
    margin-bottom: 22px;
    .filter-container {
      display: flex;
      align-items: center;
      .search-icon {
        fill: #fff;
      }
    }
  }
}
</style>
