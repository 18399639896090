<template>
  <div class="addGroupDialog">
    <el-dialog
      :title="uploadGroupData ? '编辑客服组' : '新增客服组'"
      v-model="dialogVisible"
      width="30%"
      center
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        ref="formRef"
        :model="form"
        :label-position="labelPosition"
        label-width="100px"
        status-icon
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <el-row :gutter="40">
          <el-col :span="24">
            <el-form-item label="客服组名称" prop="groupName">
              <el-input
                v-model="form.groupName"
                placeholder="请填写客服组名称"
                autocomplete="new-groupName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="状态" prop="isDeleted">
              <el-select v-model="form.isDeleted" placeholder="客服组状态">
                <el-option
                  v-for="item in statusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer" style="text-align: right">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleAddGroup">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <div @click="openDialog">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ElNotification } from 'element-plus'
import { addCustomerGroup } from '@/api/customer'
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'

const fileds = {
  groupName: '客服组名称',
  isDeleted: '客服组状态'
}

const statusOption = [
  {
    label: '开启',
    value: 0
  },
  {
    label: '关闭',
    value: 1
  }
]

// eslint-disable-next-line
const props = defineProps(['uploadGroupData', 'listQuery'])
// eslint-disable-next-line
const emits = defineEmits(['uploadGroup'])

const formRef = ref(null)
const dialogVisible = ref(false)
const labelPosition = ref('left')
const form = reactive({
  groupName: '',
  isDeleted: 0
})
const store = useStore()

// 检验规则
const validateRequire = (rule, value, callback) => {
  if (!value) {
    callback(new Error(fileds[rule.field] + '必须填写'))
  } else {
    callback()
  }
}

const rules = reactive({
  groupName: [{ required: true, validator: validateRequire }]
})

const openDialog = () => {
  init()
  dialogVisible.value = true
}

const handleAddGroup = () => {
  submitForm('formRef')
}

const submitForm = (formName) => {
  formRef.value.validate((valid) => {
    if (valid) {
      addOrUpdateGroup()
    } else {
      return false
    }
  })
}

const addOrUpdateGroup = async () => {
  if (props.uploadGroupData) {
    // 编辑
    console.log('编辑')
  } else {
    // 新增
    const data = Object.assign({}, form)
    data.userId = store.getters.userInfo.id
    await addCustomerGroup(data).then(() => {
      ElNotification({
        title: '成功',
        message: '添加客服组成功',
        type: 'success'
      })
    })
  }
  handleClose()
  // eslint-disable-next-line
  emits('uploadGroup')
}

const handleClose = () => {
  dialogVisible.value = false
  formRef.value.resetFields()
}

const init = () => {}
</script>

<style lang="scss" scoped></style>
